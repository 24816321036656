import AnchorBtn from "../components/AnchorBtn/AnchorBtn";
import tAsString from "../helpers/handleTranslate";

export default function NotFound() {
	return (
		<div className="h-screen w-screen bg-purple-radial text-WhiteWivenn flex justify-center items-center">
			<div className="text-center items-center flex flex-col gap-y-10">
				<span className="text-8xl text-orange-500">404</span>
				<span className="text-6xl">{tAsString("page_not_found")} :(</span>
				<div>
					<AnchorBtn
						anchor="/"
						primary={false}
						value={tAsString("go_back_home")}
						whiteVariation
					/>
				</div>
			</div>
		</div>
	);
}
