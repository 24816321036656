export const redStyle =
	"text-white border-RedWivenn bg-RedWivenn hover:border-BrighterRedWivenn hover:bg-BrighterRedWivenn focus:border-BrighterRedWivenn focus:bg-BrighterRedWivenn focus:shadow-allSidesRed disabled:border-RedWivenn disabled:bg-RedWivenn";
export const greenStyle =
	"text-white border-GreenWivenn bg-GreenWivenn hover:border-BrighterGreenWivenn hover:bg-BrighterGreenWivenn focus:border-BrighterGreenWivenn focus:bg-BrighterGreenWivenn focus:shadow-allSidesGreen disabled:border-GreenWivenn disabled:bg-GreenWivenn";
export const purpleStyle =
	"text-white border-PurpleWivenn bg-PurpleWivenn hover:border-BrighterPurpleWivenn hover:bg-BrighterPurpleWivenn focus:border-BrighterPurpleWivenn focus:bg-BrighterPurpleWivenn focus:shadow-allSidesPurple disabled:border-PurpleWivenn disabled:bg-PurpleWivenn";
export const whiteStyle =
	"text-PurpleWivenn border-white bg-white hover:bg-white/80 hover:border-transparent focus:border-transparent focus:bg-white/80 disabled:border-transparent disabled:bg-white/40";

export const primaryStyle = (color: "red" | "purple" | "green" | "white") => {
	switch (color) {
		case "red":
			return redStyle;
		case "green":
			return greenStyle;
		case "purple":
			return purpleStyle;
		case "white":
			return whiteStyle;
	}
};
