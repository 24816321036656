import "color-calendar/dist/css/theme-glass.css";
import UpdateComplianceDataHook from "../hooks/UpdateComplianceDataHook/UpdateComplianceDataHook";
import RegisterCpfInput from "../components/RegisterCpfInput/RegisterCpfInput";
import RegisterPassportInput from "../components/RegisterPassportInput/RegisterPassportInput";
import RegisterPhoneInput from "../components/RegisterPhoneInput/RegisterPhoneInput";
import { SubmitButton as SubmitBtn } from "@wivenn/wivenn-shared-visual-components";
import { logoutHelper } from "../helpers/userAuthenticationHelper/userAuthenticationHelper";
import tAsString from "../helpers/handleTranslate";

export default function UpdateComplianceData(): JSX.Element {
	const { complianceData, handlePartialField, dispatchFields } =
		UpdateComplianceDataHook();

	return (
		<form
			className="h-full w-full flex flex-col gap-y-4 items-center bg-PurpleWivenn text-white overflow-auto"
			onSubmit={(e) => {
				e.preventDefault();
				dispatchFields();
			}}
		>
			<div>
				<img
					alt="Experience"
					className="w-72 mt-2"
					src="/assets/images/LogoWivennPrincipalBranco.png"
				/>
			</div>
			<div
				className={`flex flex-col lg:w-[1000px] ${
					complianceData.pendingData.filter(
						(item) => item.data === "birth_date",
					).length > 0
						? "gap-y-10"
						: "gap-y-20"
				} h-fit px-4`}
			>
				<span className="text-center sm:-mb-6 mt-4 sm:text-lg">
					{tAsString("some_data_invalid_try_again")}
				</span>
				<div
					className={`${
						complianceData.pendingData.filter(
							(item) => item.data === "birth_date",
						).length > 0
							? "flex gap-x-3 sm:flex-row flex-col-reverse items-center sm:items-start pt-4"
							: "hidden"
					}`}
				>
					<div className="flex flex-col gap-y-2">
						<div className="min-w-fit" id="myCal2" />
						<span className="pl-11 font-bold">
							{tAsString("you_were_born_on")}{" "}
							<span className="underline">
								{new Date(complianceData.fields.birthDate).toLocaleDateString(
									navigator.language,
								)}
							</span>
						</span>
					</div>
					<div className="lg:pt-3 text-sm flex-nowrap whitespace-nowrap flex-col gap-y-2 lg:flex-row text-white/70 flex gap-x-2 h-fit lg:items-center ">
						<div className="flex justify-center items-center gap-x-2">
							<img
								alt=">"
								className="w-4 h-fit mr-3 hidden lg:block"
								src="assets/svgs/prospectedArrowRight.svg"
							/>
							<span>{tAsString("to_select_the")} </span>
							<div className="bg-[#292133] text-white font-bold px-6 py-1 rounded-lg">
								{tAsString("month")}
							</div>
							<span>e</span>
							<div className="bg-[#292133] text-white font-bold px-6 py-1 rounded-lg">
								{tAsString("year")}
							</div>
						</div>
						<span>{tAsString("click_on_calendar_text")}</span>
					</div>
				</div>
				{complianceData.pendingData.filter((item) => item.data === "cpf")
					.length > 0 && (
					<RegisterCpfInput
						autoFocus={false}
						gapValue="gap-y-2"
						onChange={(cpf) => {
							handlePartialField({
								cpf: cpf.cpf,
							});
						}}
						small
						value={complianceData.fields.cpf}
					/>
				)}
				{complianceData.pendingData.filter(
					(item) => item.data === "passport_number",
				).length > 0 && (
					<RegisterPassportInput
						gapValue="gap-y-2"
						onChange={(passport) => {
							handlePartialField({
								passport: passport.passport,
							});
						}}
						small
						value={complianceData.fields.passport}
					/>
				)}
				{complianceData.pendingData.filter(
					(item) => item.data === "phone_number",
				).length > 0 && (
					<RegisterPhoneInput
						autoFocus={false}
						gapValue="gap-y-2"
						onChange={(phone) => {
							handlePartialField({
								telephone: phone.phone,
							});
						}}
						small
						value={complianceData.fields.telephone}
					/>
				)}
			</div>
			{/* {JSON.stringify(complianceData.fields)} */}
			<div className="py-6 flex gap-x-2">
				<button
					className="py-3 px-4 font-medium w-full rounded-lg cursor-pointer transition-all duration-300 border-2 flex gap-x-2 items-center"
					onClick={logoutHelper}
					type="button"
				>
					<img alt="" className="w-5" src="/assets/svgs/logout.svg" />
					<span>{tAsString("exit")}</span>
				</button>
				<SubmitBtn color="white" value={tAsString("confirm")} />
			</div>
		</form>
	);
}
