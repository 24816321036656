import tAsString from "../../helpers/handleTranslate";

export default function RegisterLabel({
	text,
	description,
	required = true,
	small = false,
}: {
	text: string;
	description?: string;
	required?: boolean;
	small?: boolean;
}) {
	return (
		<div className="flex flex-col text-WhiteWivenn">
			<span
				className={`text-xl ${small ? "" : "sm:text-2xl"} flex gap-x-2`}
				data-testid="registerLabel"
			>
				<span>
					{text}{" "}
					{!required ? (
						<span className="text-WhiteWivenn/50 text-sm">
							({tAsString("optional")})
						</span>
					) : null}
				</span>
			</span>
			{description ? (
				<span
					className="leading-6 text-xs"
					data-testid="registerLabelDescription"
				>
					{description}
				</span>
			) : null}
		</div>
	);
}
