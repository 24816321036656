import Calendar from "color-calendar";
import { useEffect, useState } from "react";
import {
	getCompliancePendingData,
	postComplianceData,
} from "../../helpers/backendRoutes";

interface complianceDataType {
	pendingData: Array<{
		data: "birth_date" | "cpf" | "passport_number" | "phone_number";
	}>;
	fields: {
		birthDate: string;
		cpf: string;
		passport: string;
		telephone: string;
	};
	loading: boolean;
}

export default function UpdateComplianceDataHook(): {
	complianceData: complianceDataType;
	handlePartialField: (partial: Partial<complianceDataType["fields"]>) => void;
	dispatchFields: () => void;
} {
	const [complianceData, setComplianceData] = useState<complianceDataType>({
		pendingData: [],
		fields: {
			birthDate: "01/01/1980",
			cpf: "",
			passport: "",
			telephone: "+55",
		},
		loading: false,
	});

	const handlePartialField = (
		partial: Partial<complianceDataType["fields"]>,
	): void => {
		setComplianceData({
			pendingData: complianceData.pendingData,
			loading: complianceData.loading,
			fields: {
				...complianceData.fields,
				...partial,
			},
		});
	};

	const dispatchFields = (): void => {
		postComplianceData(complianceData.fields)
			.then(() => {
				window.location.replace("/");
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		// eslint-disable-next-line no-new
		const colorCalendar = new Calendar({
			id: "#myCal2",
			weekdayType: "long-lower",
			primaryColor: "#3E334C",
			theme: "glass",
			monthDisplayType: "long",
			calendarSize: "small",
			dateChanged: (currentDate: string) => {
				handlePartialField({ birthDate: currentDate });
			},
		});
		colorCalendar.setDate(new Date(complianceData.fields.birthDate));
	}, [
		complianceData.pendingData,
		complianceData.loading,
		complianceData.fields.cpf,
		complianceData.fields.passport,
		complianceData.fields.telephone,
	]);

	useEffect(() => {
		getCompliancePendingData()
			.then((res) => {
				setComplianceData({
					...complianceData,
					pendingData: res.data,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return { complianceData, handlePartialField, dispatchFields };
}
