import type { AxiosResponse } from "axios";
import {
	type LoginResponseType,
	type LoginStateType,
} from "../types/loginContext";
import axiosInstance from "./axiosInstance";

export default async function registerLogin(
	loginCredentials: LoginStateType,
): Promise<AxiosResponse<LoginResponseType>> {
	const data = new FormData();
	data.append("email", loginCredentials.email.value);
	data.append("password", loginCredentials.password.value);
	data.append("device_name", loginCredentials.device.value);

	return await axiosInstance.post<LoginResponseType>("/login", data);
}
