import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import initMixPanel from "./helpers/initMixPanel";
import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

Sentry.init({
	dsn: "https://f77f23ac620e989702a13731029d2389@o1059338.ingest.sentry.io/4505998931329024",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement: HTMLElement | null = document.getElementById("root");

if (rootElement != null) {
	window.addEventListener("load", () => {
		setTimeout(() => {
			window.scrollTo(0, 1);
		}, 0);
	});

	initMixPanel();

	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
					<App />
				</I18nextProvider>
			</Provider>
		</React.StrictMode>,
	);
} else {
	throw new Error(
		"There is not a root element where the react spa can be appended.",
	);
}
