export type LanguageType = "pt" | "en";

export const getCurrentSystemLanguage = (): LanguageType => {
	const SYSTEM_LOCALE = navigator.language;

	switch (true) {
		case SYSTEM_LOCALE.startsWith("pt"):
			return "pt";
		case SYSTEM_LOCALE.startsWith("en"):
			return "en";
		default:
			return "pt";
	}
};

export const getCurrentLanguageFromStorage = (): LanguageType => {
	const CURRENT_LANGUAGE_I18NEXT = localStorage.getItem("i18nextLng");

	switch (true) {
		case CURRENT_LANGUAGE_I18NEXT?.startsWith("pt"):
			return "pt";
		case CURRENT_LANGUAGE_I18NEXT?.startsWith("en"):
			return "en";
		default:
			return "pt";
	}
};

export const getDefaultLanguage = () =>
	getCurrentLanguageFromStorage() ?? getCurrentSystemLanguage();
