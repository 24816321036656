import axios from "axios";
import type { AxiosInstance } from "axios";
import i18n from "../i18n";

const axiosInstance: AxiosInstance = axios.create({
	baseURL: import.meta.env.VITE_BACK_END_URL,
	timeout: import.meta.env.VITE_BACK_END_TIMEOUT,
	headers: {
		Accept: "application/json",
	},
});

// Interceptor function
async function handleError(error) {
	console.error(error);
	if (error.response?.status === 422 && error.response.data?.errors) {
		try {
			// Extract error messages
			const errorMessages = Object.values(error.response.data.errors)
				.flat()
				.join("\n");

			// Display error messages to the user (replace with your preferred method)
			alert(errorMessages); // Simple alert for demonstration
		} catch (error) {
			console.error(error);
		}

		// Optionally, return the specific errors for more granular handling
		return await Promise.reject(error.response.data.errors);
	}

	// Pass through other errors
	return await Promise.reject(error);
}

// Register the interceptor with Axios
axiosInstance.interceptors.response.use(null, handleError);

axiosInstance.interceptors.request.use(async (config) => {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		if (window.grecaptcha?.enterprise) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			const token = await window.grecaptcha.enterprise.execute(
				"6LdvmJUiAAAAAHFExIU98LRnUBQe7vAAs2v6g6qy",
			);
			if (typeof token === "string") {
				config.headers.grecaptcharesponse = token;
			}
		}
	} catch (error) {
		console.error("When trying to get the recaptcha token: ", error);
	}

	config.headers["Accept-Language"] = i18n.language;
	return config;
});

export default axiosInstance;
