import type { AxiosResponse } from "axios";
import { type LoginResponseType } from "../types/loginContext";
import axiosInstance from "./axiosInstance";

export default async function sessionDetails(): Promise<
	AxiosResponse<LoginResponseType>
> {
	const token: string | null = localStorage.getItem("token");

	return await axiosInstance.get<LoginResponseType>("/sessionDetails", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}
