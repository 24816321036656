import { type ReactElement, useEffect, useCallback } from "react";
import { RouterProvider } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { isLoggedDetails } from "./app/slices/authSlice";
import { checkLogin } from "./app/slices/authThunks";
import { addSessionTokenToAxiosInstance } from "./helpers/userSessionDetailsHelper/handleAxiosAuthorization";
import AppRoutesHook from "./hooks/AppRoutesHook/AppRoutesHook";
import NotFound from "./screens/NotFound";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

function App(): ReactElement {
	const dispatch = useAppDispatch();
	const isLogged = useAppSelector(isLoggedDetails);
	const { router } = AppRoutesHook(isLogged);

	const useStoredTokenForLogged = useCallback(async () => {
		await dispatch(checkLogin());
	}, []);

	useEffect(() => {
		const handleStorageEvent = () => {
			void useStoredTokenForLogged();
		};

		void useStoredTokenForLogged();

		window.addEventListener("storage", function (event) {
			if (event.key === "token") handleStorageEvent();
		});
	}, []);

	useEffect(() => {
		if (isLogged !== false) addSessionTokenToAxiosInstance();
		try {
			if (
				window.hj !== undefined &&
				isLogged !== undefined &&
				isLogged !== false
			) {
				window.HTMLObjectElement("identify", isLogged.name, isLogged);
			}

			if (isLogged !== false) Sentry.setUser(isLogged?.personalData ?? null);
		} catch (error) {}
	}, [isLogged]);

	return (
		<RouterProvider
			fallbackElement={<NotFound />} // TODO: Apontar para o componente de erro global.
			key="app_main_router_provider"
			router={router}
		/>
	);
}

export default withTranslation()(App);
