import { LoadingPage } from "@wivenn/wivenn-shared-visual-components";
import { union } from "lodash";
import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router-dom";
import { type IsLoggedDetailsType } from "../../app/slices/authSlice";
import UpdateComplianceData from "../../screens/UpdateComplianceData";

const SelectRegisterOption = lazy(
	async () => await import("../../screens/SelectRegisterOption"),
);
const ResetPasswordRequest = lazy(
	async () => await import("../../screens/ResetPasswordRequest"),
);
const ResetPassword = lazy(
	async () => await import("../../screens/ResetPassword"),
);
const ProspectedExpertRegister = lazy(
	async () => await import("../../screens/ProspectedExpertRegister"),
);
const SpontaneousExpertRegister = lazy(
	async () => await import("../../screens/SpontaneousExpertRegister"),
);
const Login = lazy(async () => await import("../../screens/Login"));
const Workspace = lazy(async () => await import("../../screens/Workspace"));
const NotFound = lazy(async () => await import("../../screens/NotFound"));
const DispatchContract = lazy(
	async () => await import("../../screens/DispatchContract"),
);
const EmbedInteraction = lazy(
	async () => await import("../../screens/EmbedInteraction"),
);
const LgpdDeletion = lazy(
	async () => await import("../../screens/LgdpDeletion"),
);

const BiographyView = lazy(
	async () => await import("../../screens/BiographyView"),
);

const BiographiesGridView = lazy(
	async () => await import("../../screens/BiographiesGridView"),
);

const BlindBiographyView = lazy(
	async () => await import("../../screens/BlindBiographyView"),
);

const publicRoutes = (): RouteObject[] => [
	{
		path: "registerOption",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<SelectRegisterOption />
			</Suspense>
		),
	},
	{
		path: "biografias/:id",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<BiographiesGridView />
			</Suspense>
		),
	},
	{
		path: "biografia/:id",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<BiographyView />
			</Suspense>
		),
	},
	{
		path: "blind_biography/:id",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<BlindBiographyView />
			</Suspense>
		),
	},
	{
		path: "forgotPassword",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<ResetPasswordRequest />
			</Suspense>
		),
	},
	{
		path: "reset-password/*",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<ResetPassword />
			</Suspense>
		),
	},
	{
		path: "register/:token",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<ProspectedExpertRegister />
			</Suspense>
		),
	},
	{
		path: "register",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<ProspectedExpertRegister />
			</Suspense>
		),
	},
	{
		path: "spontaneousRegister",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<SpontaneousExpertRegister />
			</Suspense>
		),
	},
	{
		id: "publicEmbed",
		path: "video/:expertAccessHash",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<EmbedInteraction />
			</Suspense>
		),
	},
	{
		path: "*",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<Login />
			</Suspense>
		),
	},
];

const privateRoutes: (isLogged: IsLoggedDetailsType | false) => RouteObject[] =
	(isLogged: IsLoggedDetailsType | false) => [
		{
			id: "privateEmbed",
			path: "video/:expertAccessHash",
			element: (
				<Suspense fallback={<LoadingPage theme="purple" />}>
					<EmbedInteraction />
				</Suspense>
			),
		},
		{
			path: "*",
			element: (
				<Suspense fallback={<LoadingPage theme="purple" />}>
					{isLogged !== false &&
					Number(isLogged.personalData.revision_flag) === 3 ? (
						<UpdateComplianceData />
					) : isLogged !== false && isLogged.personalData.is_latest_contract ? (
						<Workspace />
					) : (
						<DispatchContract />
					)}
				</Suspense>
			),
		},
	];

const generalRoutes = (): RouteObject[] => [
	{
		path: "lgpd/deletion/:id",
		element: (
			<Suspense fallback={<LoadingPage theme="purple" />}>
				<LgpdDeletion />
			</Suspense>
		),
	},
];

export const routes: (
	isLogged: IsLoggedDetailsType | false | undefined,
) => RouteObject[] = (isLogged: IsLoggedDetailsType | false | undefined) => {
	if (isLogged !== undefined) {
		return [
			{
				path: "*",
				errorElement: (
					<Suspense fallback={<LoadingPage theme="purple" />}>
						<NotFound />
					</Suspense>
				),
				children: union(
					generalRoutes(),
					isLogged ? privateRoutes(isLogged) : publicRoutes(),
				),
			},
		];
	}

	return [
		{
			path: "*",
			element: <LoadingPage theme="purple" />,
		},
	];
};
