import { type LoginResponseType } from "../../types/loginContext";

export const saveUserSessionHelper = (payload: LoginResponseType): void => {
	if (localStorage.getItem("token") !== payload.token)
		localStorage.setItem("token", payload.token);
};

export const removeUserSessionHelper = (): void => {
	localStorage.removeItem("token");
	window.location.reload();
};
