import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import en from "./language/en.json";
import pt from "./language/pt.json";
import { getDefaultLanguage } from "./helpers/getDefaultLanguageBySystem";

const resources = {
	en: {
		translation: en,
	},
	pt: {
		translation: pt,
	},
};

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: getDefaultLanguage(),
	})
	.catch(console.error);

export default i18n;
