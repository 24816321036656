import { createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { type RootState } from "../../app/store";
import { saveUserSessionHelper } from "../../helpers/userSessionDetailsHelper/saveUserSessionHelper";
import { type PendencyType } from "../../types/workspaceContext";
import { attemptLogin, checkLogin } from "./authThunks";
import tAsString from "../../helpers/handleTranslate";

export interface IsLoggedDetailsType {
	name: string;
	token: string;
	expires_in: number | null;
	permissions: string[] | undefined;
	personalData: {
		cpf: null | string | number;
		email: null | string | number;
		pendencies: PendencyType[];
		perHourPrice: null | string | number;
		pis: null | string | number;
		phone: null | string | number;
		linkedin: null | string;
		last_updated: string;
		curriculum: null | string;
		guest_token: null | string;
		compliance_date: null | string;
		is_latest_contract: boolean;
		last_commitment_term_submission_at: string | null;
		signup_at: string | null;
		revision_flag: number | string | null;
		commentary: string | null;
		citizenchip: number;
	};
}

export interface AuthStatusState {
	context:
		| {
				message: string | undefined;
				errors:
					| {
							email: string | undefined;
							password: string | undefined;
					  }
					| undefined;
		  }
		| undefined;
}
export interface AuthState {
	isLogged: IsLoggedDetailsType | false | undefined;
	status: AuthStatusState | undefined;
}

const initialState: AuthState = {
	isLogged: undefined,
	status: undefined,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		resetInitial: () => initialState,
	},
	extraReducers(builder) {
		// Login Check
		builder.addCase(checkLogin.pending, () => ({
			isLogged: undefined,
			status: {
				context: undefined,
			},
		}));

		builder.addCase(checkLogin.fulfilled, (state, action) => {
			if (action.payload) {
				return {
					isLogged: {
						expires_in: action.payload.expires_in,
						name: action.payload.name,
						permissions: action.payload.permissions,
						token: action.payload.token,
						personalData: {
							cpf: action.payload.cpf,
							email: action.payload.email,
							pendencies: action.payload.pendencies,
							perHourPrice: action.payload.perHourPrice,
							pis: action.payload.pis,
							phone: action.payload.phone,
							curriculum: action.payload.curriculum,
							last_updated: action.payload.last_updated,
							linkedin: action.payload.linkedin,
							guest_token: action.payload.guest_token,
							compliance_date: action.payload.compliance_date,
							is_latest_contract: action.payload.is_latest_contract,
							last_commitment_term_submission_at:
								action.payload.last_commitment_term_submission_at,
							signup_at: action.payload.signup_at,
							revision_flag: action.payload.revision_flag,
							commentary: action.payload.commentary,
							citizenchip: action.payload.citizenchip,
						},
					},
					status: undefined,
				};
			}

			return {
				isLogged: false,
				status: undefined,
			};
		});
		builder.addCase(checkLogin.rejected, () => ({
			isLogged: false,
			status: undefined,
		}));
		builder.addCase(attemptLogin.pending, () => ({
			isLogged: false,
			status: {
				context: undefined,
			},
		}));
		builder.addCase(attemptLogin.fulfilled, (state, action) => {
			saveUserSessionHelper(action.payload);
			return {
				isLogged: {
					expires_in: action.payload.expires_in,
					name: action.payload.name,
					permissions: action.payload.permissions,
					token: action.payload.token,
					personalData: {
						cpf: action.payload.cpf,
						email: action.payload.email,
						pendencies: action.payload.pendencies,
						perHourPrice: action.payload.perHourPrice,
						pis: action.payload.pis,
						phone: action.payload.phone,
						curriculum: action.payload.curriculum,
						last_updated: action.payload.last_updated,
						linkedin: action.payload.linkedin,
						guest_token: action.payload.guest_token,
						compliance_date: action.payload.compliance_date,
						is_latest_contract: action.payload.is_latest_contract,
						last_commitment_term_submission_at:
							action.payload.last_commitment_term_submission_at,
						signup_at: action.payload.signup_at,
						revision_flag: action.payload.revision_flag,
						commentary: action.payload.commentary,
						citizenchip: action.payload.citizenchip,
					},
				},
				status: undefined,
			};
		});
		builder.addCase(attemptLogin.rejected, (state, action) => {
			if (isAxiosError(action.payload)) {
				switch (action.payload.response?.status) {
					case 401:
						return {
							isLogged: false,
							status: {
								context: {
									errors: {
										email: undefined,
										password: undefined,
									},
									message: action.payload.response.data,
								},
							},
						};
					case 403:
						return {
							isLogged: false,
							status: {
								context: {
									errors: {
										email: undefined,
										password: undefined,
									},
									message: tAsString("invalid_email_password_try_again"),
								},
							},
						};
					case 422:
						return {
							isLogged: false,
							status: {
								context: {
									errors: {
										email: action.payload.response.data.errors?.email
											? action.payload.response.data.errors?.email[0]
											: undefined,
										password: action.payload.response.data.errors?.password
											? action.payload.response.data.errors?.password[0]
											: undefined,
									},
									message: undefined,
								},
							},
						};
					case 500:
						return {
							isLogged: false,
							status: {
								context: {
									errors: undefined,
									message: tAsString("server_error_try_again"),
								},
							},
						};
					default:
						return {
							isLogged: false,
							status: {
								context: {
									errors: undefined,
									message: "",
								},
							},
						};
				}
			} else {
				return {
					isLogged: false,
					status: {
						context: {
							errors: undefined,
							message: "",
						},
					},
				};
			}
		});
	},
});

export const authSate = (state: RootState) => state.auth;
export const authLoginStatus = (state: RootState) => state.auth.status;
export const isLoggedDetails = (state: RootState) => state.auth.isLogged;
export const isIdle = (state: RootState) => state.auth.status === undefined;

export default authSlice.reducer;
