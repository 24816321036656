import PhoneInput from "react-phone-number-input";
import { ErrorMessageSpan } from "@wivenn/wivenn-shared-visual-components";
import RegisterLabel from "../RegisterLabel/RegisterLabel";
import "./registerPhoneInput.css";
import tAsString from "../../helpers/handleTranslate";

export default function RegisterPhoneInput({
	onChange,
	value,
	errorMessage,
	gapValue = "gap-y-10",
	small = false,
	autoFocus = true,
	orangeError,
}: {
	onChange: ({ phone }: { phone: string }) => void;
	value: string | undefined;
	errorMessage?: string;
	gapValue?: string;
	small?: boolean;
	autoFocus?: boolean;
	orangeError?: string;
}): JSX.Element {
	return (
		<div className="w-full text-WhiteWivenn">
			<div className={`flex flex-col gap-y-10 ${gapValue}`}>
				<RegisterLabel small={small} text={tAsString("prospect_phone_label")} />
				<div className="w-full">
					<PhoneInput
						autoFocus={autoFocus}
						className={`transition-colors duration-150 appearance-none focus:outline-none focus:ring-0 block px-0 w-full ${
							small ? "" : "text-lg"
						} ${
							errorMessage
								? "text-RedWivenn/70 border-RedWivenn/70 focus:border-RedWivenn/70 focus:text-white"
								: ""
						} ${
							orangeError
								? "text-yellow-500/70 border-yellow-500/70 focus:border-yellow-500/70 focus:text-white"
								: ""
						} ${
							!errorMessage && !orangeError
								? "text-white border-WhiteWivenn/80 focus:border-WhiteWivenn/80"
								: ""
						} bg-transparent border-0 border-b-2 pb-1 peer`}
						data-testid="phoneInput"
						limitMaxLength
						onChange={(e) => {
							e != null &&
								onChange({
									phone: e,
								});
						}}
						placeholder={tAsString("prospect_phone_placeholder")}
						required
						value={value}
					/>
				</div>
			</div>
			<div className="h-1">
				{errorMessage ? (
					<ErrorMessageSpan>{errorMessage}</ErrorMessageSpan>
				) : null}
				{orangeError ? (
					<ErrorMessageSpan requiredAlert>{orangeError}</ErrorMessageSpan>
				) : null}
			</div>
		</div>
	);
}
