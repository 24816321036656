import Swal from "sweetalert2";
import axiosInstance from "../../services/axiosInstance";
import tAsString from "../handleTranslate";

export const addSessionTokenToAxiosInstance = (): void => {
	axiosInstance.interceptors.response.use(
		(response) => response,
		async (err) => {
			if (err?.response?.status === 429) {
				Swal.fire({
					title: tAsString("many_requests"),
					text: tAsString("wait_continue_using_system"),
					icon: "error",
					showConfirmButton: false,
					timer: 4000,
					timerProgressBar: true,
					iconColor: "#B62836",
				});
			}

			if (err?.response?.status === 500) {
				Swal.fire({
					title: tAsString("error_occurred_your_request"),
					text: tAsString("if_error_persists_contact_wivenn"),
					icon: "error",
					showConfirmButton: false,
					timer: 4000,
					timerProgressBar: true,
					iconColor: "#B62836",
				});
			}

			return await Promise.reject(err);
		},
	);

	axiosInstance.interceptors.request.use((config) => {
		if (config.headers) {
			config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
		}

		return config;
	});
};

export const removeSessionTokenFromAxiosInstance = (): void => {
	axiosInstance.interceptors.request.use((config) => {
		delete config.headers?.Authorization;
		return config;
	});
};
