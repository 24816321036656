import { createAsyncThunk } from "@reduxjs/toolkit";
import registerLogin from "../../services/registerLogin";
import sessionDetails from "../../services/sessionDetails";
import { type LoginStateType } from "../../types/loginContext";

export const attemptLogin = createAsyncThunk(
	"auth/attemptLogin",
	async (loginState: LoginStateType, { rejectWithValue }) => {
		try {
			const response = await registerLogin(loginState);
			return response.data;
		} catch (err) {
			/*
			 * Use `err.response.data` as `action.payload` for a `rejected` action,
			 * by explicitly returning it using the `rejectWithValue()` utility
			 */
			return rejectWithValue(err);
		}
	},
);

export const checkLogin = createAsyncThunk("auth/checkLogin", async () => {
	const response = await sessionDetails();

	return response.data;
});
