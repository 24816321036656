import { primaryStyle } from "../../helpers/primaryButtonStyle/primaryButtonStyle";
import { secondaryStyle } from "../../helpers/secondaryButtonStyle/secondaryButtonStyle";

export default function AnchorBtn({
	anchor,
	value,
	color = "purple",
	primary = true,
	whiteVariation = false,
	wFull = true,
}: {
	anchor: string;
	value: string;
	color?: "red" | "purple" | "green";
	primary?: boolean;
	whiteVariation?: boolean;
	wFull?: boolean;
}) {
	return (
		<a className="w-full" data-testid="anchor" href={anchor}>
			<button
				className={`${
					primary ? primaryStyle(color) : secondaryStyle(color, whiteVariation)
				} ${
					wFull && "w-full"
				} py-3 px-10 font-bold rounded-lg cursor-pointer transition-all duration-300 border-2`}
				data-testid="anchorBtn"
			>
				{value}
			</button>
		</a>
	);
}
