export const redWhiteVariationStyle =
	"bg-transparent text-white border-white hover:bg-white hover:text-RedWivenn hover:border-BrighterRedWivenn focus:bg-BrighterRedWivenn focus:text-white focus:shadow-allSidesRed focus:border-BrighterRedWivenn focus:bg-BrighterRedWivenn disabled:bg-BrighterRedWivenn disabled:border-BrighterRedWivenn";
export const redNotWhiteVariationStyle =
	"bg-transparent text-RedWivenn border-RedWivenn hover:border-BrighterRedWivenn hover:bg-BrighterRedWivenn hover:text-white focus:border-BrighterRedWivenn focus:bg-BrighterRedWivenn focus:shadow-allSidesRed focus:text-white  disabled:bg-BrighterRedWivenn disabled:border-BrighterRedWivenn";
export const greenWhiteVariationStyle =
	"bg-transparent text-white border-white hover:bg-white hover:text-GreenWivenn hover:border-white focus:bg-BrighterGreenWivenn focus:text-white focus:shadow-allSidesGreen focus:border-BrighterGreenWivenn focus:bg-BrighterGreenWivenn  disabled:text-WhiteWivenn/20 disabled:bg-transparent disabled:border-WhiteWivenn/20 disabled:cursor-default";
export const greenNotWhiteVariationStyle =
	"bg-transparent text-GreenWivenn border-GreenWivenn hover:border-BrighterGreenWivenn hover:bg-BrighterGreenWivenn hover:text-white focus:border-BrighterGreenWivenn focus:bg-BrighterGreenWivenn focus:shadow-allSidesGreen focus:text-white disabled:bg-BrighterGreenWivenn disabled:border-BrighterGreenWivenn";
export const purpleWhiteVariationStyle =
	"bg-transparent text-white  border-white hover:bg-white hover:text-PurpleWivenn hover:border-white focus:text-white focus:shadow-allSidesPurple focus:border-BrighterPurpleWivenn focus:bg-BrighterPurpleWivenn disabled:text-gray-500/40 disabled:bg-transparent disabled:border-gray-500/40 disabled:cursor-default";
export const purpleNotWhiteVariationStyle =
	"bg-transparent text-PurpleWivenn border-PurpleWivenn hover:border-BrighterPurpleWivenn hover:bg-BrighterPurpleWivenn hover:text-white focus:border-BrighterPurpleWivenn focus:bg-BrighterPurpleWivenn focus:shadow-allSidesPurple focus:text-white disabled:bg-BrighterPurpleWivenn disabled:border-BrighterPurpleWivenn";

export const secondaryStyle = (
	color: "red" | "purple" | "green" | "white",
	whiteVariation: boolean,
) => {
	switch (color) {
		case "red":
			return `${
				whiteVariation ? redWhiteVariationStyle : redNotWhiteVariationStyle
			}`;
		case "green":
			return `${
				whiteVariation ? greenWhiteVariationStyle : greenNotWhiteVariationStyle
			}`;
		case "purple":
			return `${
				whiteVariation
					? purpleWhiteVariationStyle
					: purpleNotWhiteVariationStyle
			}`;
	}
};
