import { ErrorMessageSpan } from "@wivenn/wivenn-shared-visual-components";
import RegisterLabel from "../RegisterLabel/RegisterLabel";
import tAsString from "../../helpers/handleTranslate";

export default function RegisterCpfInput({
	onChange,
	value,
	errorMessage,
	gapValue = "gap-y-10",
	small = false,
	autoFocus = true,
}: {
	onChange: ({ cpf }: { cpf: string }) => void;
	value: string | undefined;
	errorMessage?: string;
	gapValue?: string;
	small?: boolean;
	autoFocus?: boolean;
}): JSX.Element {
	const parseChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const TARGET_VALUE: string = event.target.value;
		onChange({ cpf: TARGET_VALUE });
	};

	return (
		<div className="w-full text-WhiteWivenn">
			<div className={`flex flex-col ${gapValue}`}>
				<RegisterLabel small={small} text={tAsString("prospect_cpf_label")} />
				<div>
					<input
						autoComplete="off"
						autoFocus={autoFocus}
						className={`transition-colors duration-150 appearance-none focus:outline-none focus:ring-0 block px-0 w-full ${
							small ? "" : "text-lg"
						} ${
							errorMessage != null
								? "text-RedWivenn/70 border-RedWivenn/70 focus:border-RedWivenn/70 focus:text-white"
								: "text-white border-WhiteWivenn/80 focus:border-WhiteWivenn/80"
						} bg-transparent border-0 border-b-2 peer`}
						data-testid="cpfInput"
						maxLength={14}
						onChange={parseChange}
						placeholder="000.000.000-00"
						required
						type="text"
						value={value}
					/>
				</div>
			</div>
			<div className="h-1">
				{errorMessage != null && (
					<ErrorMessageSpan>{errorMessage}</ErrorMessageSpan>
				)}
			</div>
		</div>
	);
}
