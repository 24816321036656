import { useEffect, useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import { type AuthState } from "../../app/slices/authSlice";
import { routes } from "./routesHelper";

export default function AppRoutesHook(isLogged: AuthState["isLogged"]) {
	const [router, updateRouter] = useState(
		createBrowserRouter(routes(isLogged)),
	);

	useEffect(() => {
		updateRouter(createBrowserRouter(routes(isLogged)));
	}, [isLogged]);

	return { router };
}
